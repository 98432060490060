import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import PhotoGrid from "../components/PhotoGrid"

const PersonPage = ({ data }) => (
  <div>
    <h1 css={{ padding: "0 30px", marginTop: 30, textAlign: "center" }}>
      Photos of {data.personData.alias}
    </h1>

    <PhotoGrid photos={data.photoData.photos} />
  </div>
)

export default PersonPage

PersonPage.propTypes = {
  data: PropTypes.shape({
    personData: PropTypes.shape({
      alias: PropTypes.string.isRequired,
      slug: PropTypes.shape({
        current: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,

    photoData: PropTypes.shape({
      photos: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string.isRequired,
          caption: PropTypes.string,
          image: PropTypes.shape({
            asset: PropTypes.object.isRequired,
          }).isRequired,
          nsfw: PropTypes.bool,
          subjects: PropTypes.arrayOf(
            PropTypes.shape({
              _id: PropTypes.string.isRequired,
              alias: PropTypes.string.isRequired,
            }).isRequired
          ),
        }).isRequired
      ).isRequired,
    }).isRequired,
  }).isRequired,
}

export const query = graphql`
  query Person($_id: String!) {
    personData: sanityPerson(_id: { eq: $_id }) {
      alias
      slug {
        current
      }
    }

    photoData: allSanityPhoto(
      filter: { subjects: { elemMatch: { _id: { eq: $_id } } } }
    ) {
      photos: nodes {
        _id
        caption
        image {
          asset {
            url
            _id
          }
          ...ImagePresentationData
        }
        nsfw
        subjects {
          _id
          alias
        }
      }
    }
  }
`
